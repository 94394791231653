
import { useRoute } from 'vue-router'
import { homeworkActiveTab } from '@/states/layout'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Introduction: defineAsyncComponent(() => import('./Introduction.vue')),
    ActualCombatSpace: defineAsyncComponent(() => import('./ActualCombatSpace.vue'))
  },
  setup: () => {
    const route = useRoute()
    const { courseId } = route.params
    return {
      homeworkActiveTab,
      courseId
    }
  }
}
